
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import SingleImageUpload from '@/component/single-image-upload.vue';
import moment, { Moment } from 'moment';
import {
  getZoneWare,
  Zone,
  initZoneData,
  wareData,
  WareData,
  initWareData,
  getCategoryData,
  initCategory,
  Category,
  addMessageType,
  getMessageDetail,
  editMessage,
} from '@/api/operation/message_push';
import { PaginationData } from '@/api/operation/customer';
import { getValidatorMap, Validate } from '@/mixin/validator';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
import NEditor from '@/component/NForm/NEditor/NEditor.vue';
const UPLOAD_DIR = {
  activity_image: 'operation/activity_image',
};
const { VUE_APP_API: API } = process.env;

interface Form {
  title: string;
  content: string;
  banner: string;
  special: any[];
  ware_detail: any[];
  page_url: string;
  push_time: any;
  sub_title?: any;
}
function initImgTextLink(remote?: any): Form {
  remote = remote || {};
  return {
    title: remote.title || '',
    content: remote.content || '',
    banner: remote.banner || '',
    special: [],
    ware_detail: [],
    page_url: remote.page_url || remote.remark || '',
    push_time: null,
    sub_title: remote.sub_title || '',
  };
}
const WARE_STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '上架中',
  },
  {
    id: 2,
    value: '已下架',
  },
];
const _ = {
  findIndex: require('lodash/findIndex'),
};
const TITLE_MAX_LENGTH = 6;
@Component({
  components: {
    SingleImageUpload,
    NEditor,
  },
})
export default class AddMessage extends Mixins(Validate) {
  globalFunction = useGlobalFunction().globalFunction;
  form: Form = initImgTextLink();
  is_edit: boolean = false; // 是否是编辑
  is_view: boolean = false; // 是否是查看
  is_loading: boolean = false;
  is_show_special_area_modal: boolean = false;
  is_show_ware_detail_modal: boolean = false;
  form_item_layout: any = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
  };
  message_type: number = 1;
  jump_type: number = 1;
  jump_page: number = 1;
  zone_data: PaginationData<Zone> = initZoneData(); // 专区数据
  ware_data: PaginationData<WareData> = initWareData(); // 商品数据
  category_data: Category[] = initCategory(); // 分类数据
  zone_title: string = '';
  ware_modal_filter_data: any = {
    title: '',
    id: '',
    category: [],
    status: 0,
  };
  selectedWareRowKeys: any = []; // 被选中的商品id
  selectedZoneRowKeys: any = []; // 被选中的特色专区id
  push_time_radio: number = 1;
  validator_list: any = [
    {
      field: 'title',
      message: '请填写活动标题',
    },
    {
      field: 'content',
      message: '请填写活动内容',
    },
    {
      field: 'push_time',
      message: '请选择推送时间',
      validate: null,
      status: 'success',
    },
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  preview_image: string = '';
  preview_visible: boolean = false;
  get titleLength() {
    return TITLE_MAX_LENGTH - this.form.title.length;
  }
  get wareStatus() {
    return WARE_STATUS;
  }
  get UPLOAD_DIR() {
    return UPLOAD_DIR;
  }

  paginationOption(data: any) {
    return {
      current: +data.current || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      total: data.total || 0,
    };
  }

  @Watch('message_type')
  watchMessageType() {
    this.addValidateRule();
  }

  @Watch('jump_type')
  watchMessageType2() {
    this.addValidateRule();
  }
  // 根据消息类型/跳转类型添加表单验证规则
  addValidateRule() {
    this.validator_list = [
      {
        field: 'title',
        message: '请填写活动标题',
      },
      {
        field: 'content',
        message: '请填写活动内容',
      },
      {
        field: 'push_time',
        message: '请选择推送时间',
      },
    ];
    this.jump_type === 2
      ? this.validator_list.push({
          field: 'page_url',
          message: '请输入跳转页面',
        })
      : '';
    this.message_type === 1
      ? this.validator_list.push({
          field: 'banner',
          message: '请选择活动封面图',
        })
      : '';
    this.message_type === 4
      ? this.validator_list.push({
          field: 'banner',
          message: '请选择活动封面图',
        })
      : '';
    this.validatorMap = getValidatorMap(this.validator_list);
  }

  handlePreview(imageUrl: string) {
    this.preview_image = imageUrl;
    this.preview_visible = true;
  }

  // 获取分类列表
  @changeLoading(['is_loading'])
  async getCategoryData() {
    const res: any = await getCategoryData();
    this.category_data = initCategory();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.category_data = initCategory(res.data);
  }
  // 获取专区列表
  @changeLoading(['is_loading'])
  async getZoneWare(current?: number, title?: string, limit: number = 5) {
    const res: any = await getZoneWare(current, title, limit);
    this.zone_data = initZoneData();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.zone_data = initZoneData(res.data);
  }
  // 获取商品列表
  @changeLoading(['is_loading'])
  async getWareData(query_params: any = { current: 1, page: 5 }) {
    const res: any = await wareData(query_params);
    this.ware_data = initWareData();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.ware_data = initWareData(res.data);
    this.ware_data.detail.forEach((ware: any) => {
      this.category_data.forEach((category: any) => {
        if (ware.category.parent_id === category.id && ware.category.parent_id !== 0) {
          ware.category.parent_name = `${category.name}/`;
        }
      });
    });
  }
  // 搜索特色专区
  @changeLoading(['is_loading'])
  async searchZone() {
    await this.getZoneWare(1, this.zone_title, 5);
  }
  // 搜索商品
  @changeLoading(['is_loading'])
  async searchWare() {
    const query_params = {
      product_id: this.ware_modal_filter_data.id,
      product_name: this.ware_modal_filter_data.title,
      category_id:
        this.ware_modal_filter_data.category[this.ware_modal_filter_data.category.length - 1],
      is_show: this.ware_modal_filter_data.status === 0 ? '' : this.ware_modal_filter_data.status,
      current: 1,
      page: 5,
    };
    await this.getWareData(query_params);
  }

  // 打开模态窗
  openModal(type: number) {
    // type  1为特色专区模态窗 2为商品模态窗
    if (type === 1) {
      this.selectedZoneRowKeys = [];
      this.is_show_special_area_modal = true;
      this.form.special[0] ? this.selectedZoneRowKeys.push(this.form.special[0].id) : '';
    } else {
      this.selectedWareRowKeys = [];
      this.is_show_ware_detail_modal = true;
      this.form.ware_detail[0] ? this.selectedWareRowKeys.push(this.form.ware_detail[0].id) : '';
    }
  }
  // 关闭商品模态窗
  @changeLoading(['is_loading'])
  async closeWareModal() {
    this.is_show_ware_detail_modal = false;
    this.ware_modal_filter_data = {
      title: '',
      id: '',
      category: [],
      status: 0,
    };
    await this.getWareData();
  }
  // 关闭特色专区模态窗
  @changeLoading(['is_loading'])
  async closeZoneModal() {
    this.is_show_special_area_modal = false;
    this.zone_title = '';
    await this.getZoneWare(1, '', 5);
  }
  // 切换商品分页
  @changeLoading(['is_loading'])
  async handleWareTable(data: any) {
    const query_params = {
      product_id: this.ware_modal_filter_data.id,
      product_name: this.ware_modal_filter_data.title,
      category_id:
        this.ware_modal_filter_data.category[this.ware_modal_filter_data.category.length - 1],
      is_show: this.ware_modal_filter_data.status === 0 ? '' : this.ware_modal_filter_data.status,
      current: data.current,
      page: 5,
    };
    await this.getWareData(query_params);
  }
  // 切换特色专区分页
  @changeLoading(['is_loading'])
  async handleZoneChange(data: any) {
    await this.getZoneWare(data.current, this.zone_title, 5);
  }
  // 勾选商品
  onSelectWareChange(selectedRowKeys: any) {
    this.selectedWareRowKeys = selectedRowKeys;
  }
  // 勾选色特专区时
  onSelectZoneChange(selectedRowKeys: any) {
    this.selectedZoneRowKeys = selectedRowKeys;
  }
  // 切换消息类型
  changeMessageType() {
    this.form = initImgTextLink();
    this.push_time_radio = 1;
    this.jump_type = 1;
    this.jump_page = 1;
  }
  // 切换跳转类型
  changeJumpType() {
    this.form.special = [];
    this.form.ware_detail = [];
    this.form.page_url = '';
  }
  // 切换推送时间
  changePushType() {
    if (this.push_time_radio === 1) {
      this.form.push_time = null;
    }
    if (this.push_time_radio === 1) {
      this.validatorMap.push_time.validate = () => true;
    } else {
      this.validatorMap.push_time.validate = value => !!value;
    }
  }
  // 确定添加色特专区
  @changeLoading(['is_loading'])
  async confirmAddZone() {
    this.form.special = this.zone_data.detail.filter((item: any) => {
      return item.id === this.selectedZoneRowKeys[0];
    });
    await this.closeZoneModal();
  }
  // 确定添加商品
  @changeLoading(['is_loading'])
  async confirmAddWare() {
    this.form.ware_detail = this.ware_data.detail.filter((item: any) => {
      return item.id === this.selectedWareRowKeys[0];
    });
    await this.closeWareModal();
  }
  // 提交
  @changeLoading(['is_loading'])
  async submit() {
    if (this.push_time_radio === 1) {
      this.validatorMap.push_time.validate = () => true;
    } else {
      this.validatorMap.push_time.validate = value => !!value;
    }
    if (!this.validateCommit()) {
      return;
    }
    if (this.message_type !== 3 && this.jump_type === 1 && this.message_type !== 4) {
      if (this.form.special.length <= 0 && this.jump_page === 1) {
        this.$message.error('请选择特色专区');
        return;
      }
      if (this.form.ware_detail.length <= 0 && this.jump_page === 2) {
        this.$message.error('请选择商品');
        return;
      }
    }
    const send_data = {
      type: this.message_type,
      title: this.form.title,
      content: this.form.content,
      banner: this.form.banner,
      jump_type:
        this.message_type !== 1 && this.message_type !== 2
          ? 0
          : this.jump_type === 1
          ? this.jump_page
          : 5,
      page_type: this.message_type !== 1 && this.message_type !== 2 ? 0 : this.jump_page,
      page_url: this.form.page_url,
      zone_id: this.form.special[0] ? this.form.special[0].id : 0,
      product_id: this.form.ware_detail[0] ? this.form.ware_detail[0].id : 0,
      push_now: this.push_time_radio === 1 ? 1 : 0,
      push_time: 0,
      remark: '',
      sub_title: this.form.sub_title,
    };
    switch (send_data.jump_type) {
      case 1:
        send_data.remark = this.form.special[0] ? this.form.special[0].id : 0;
        break;
      case 2:
        send_data.remark = this.form.ware_detail[0] ? this.form.ware_detail[0].id : 0;
        break;
      case 5:
        send_data.remark = this.form.page_url;
        break;
    }
    if (send_data.push_now === 0) {
      send_data.push_time = this.form.push_time.format('X');
    }
    let res;
    console.log('send_data', send_data);

    if (this.is_edit) {
      res = await editMessage(send_data, +this.$route.query.id);
    } else {
      res = await addMessageType(send_data);
    }
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    // this.$router.go(-1);
    this.$message.success('新增推送消息成功');
    this.$router.push({
      path: '/operation/message-push',
    });
    this.globalFunction.getMessageList();
  }
  // 编辑时获取消息详情
  @changeLoading(['is_loading'])
  async messageDetail() {
    const res: any = await getMessageDetail(+this.$route.query.id);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    const _data = res.data;
    this.form = initImgTextLink(res.data);
    this.message_type = _data.type;
    this.jump_type = _data.jump_type === 5 ? 2 : 1;
    this.jump_page = _data.page_type;
    this.push_time_radio = _data.push_now === 0 ? 2 : 1;
    this.form.push_time = _data.push_time ? moment(_data.push_time * 1000) : null;

    if (_data.page_type === 1) {
      this.zone_data.detail.forEach((item: any) => {
        if (item.id === _data.zone_id) {
          this.form.special.push(item);
        }
      });
      this.selectedZoneRowKeys.push(
        _.findIndex(this.zone_data.detail, (item: any) => {
          return item.id === _data.zone_id;
        })
      );
    }
    if (_data.page_type === 2) {
      this.ware_data.detail.forEach((item: any) => {
        if (item.id === _data.product_id) {
          this.form.ware_detail.push(item);
        }
      });
      this.selectedWareRowKeys.push(
        _.findIndex(this.ware_data.detail, (item: any) => {
          return item.id === _data.product_id;
        })
      );
    }
  }
  @changeLoading(['is_loading'])
  async created() {
    await this.getZoneWare(1, '', 5);
    await this.getCategoryData();
    await this.getWareData();
    this.is_edit = !!this.$route.query.id;
    this.is_view = !!this.$route.query.is_view;
    if (this.is_edit) {
      await this.messageDetail();
    }
    console.log(this.form.push_time);
  }
  beforeMount() {
    this.addValidateRule();
  }
}

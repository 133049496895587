import { request as axios } from '@/util/request';
import { PaginationData } from '@/api/operation/customer';
import { Moment } from 'moment';
const getUrl = require('baimahu/url-with-param');
const API = {
  get_list: '/boss/operation/message',
  cancel_send: '/boss/operation/message/status',
  zone: '/boss/operation/zone',
  ware_data: '/boss/product/spu',
  category: '/boss/product/category',
}


// 请求参数数据接口
interface QueryParams {
  search?: string;
  type?: number;
  jump_type?: number;
  start_time?: number | string;
  end_time?: number | string;
  status?: number;
  current?: number;
  limit?: number;
}
// 消息推送列表数据接口
export interface MessageData {
  banner: string;
  cancel_time: number;
  content: string;
  create_time: number;
  id: number;
  jump_type: number;
  page_type: number;
  page_url: string;
  product_id: number;
  push_now: number;
  push_time: number;
  status: number;
  title: string;
  type: number;
  update_time: number;
  zone_id: number;
}
// 初始化消息推送列表
export function initMessageData(remote?: PaginationData<MessageData>): PaginationData<MessageData> {
  remote = remote || {} as PaginationData<MessageData>;
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    limit: remote.limit || 0,
    total: remote.total || 0,
    last: remote.last || 0,
  }
}
// 获取消息推送列表
export async function getMessageList(query_params?: QueryParams) {
  const url = getUrl(API.get_list, query_params);
  return await axios.get(url);
}


// 取消发送
export async function cancelSend(id: number) {
  return await axios.put(`${API.cancel_send}/${id}`);
}



// 专区数据接口
export interface Zone {
  banner_image_url: string;
  box_count: number;
  colour_number: string;
  create_time: number;
  detail_page_title: string;
  home_image_plus_url: string;
  home_image_url: string;
  id: number;
  sort: number;
  status: number;
  style: number;
  subtitle: string;
  title: string;
  update_time: number;
}
// 初始化专区数据
export function initZoneData(remote?: PaginationData<Zone>): PaginationData<Zone> {
  remote = remote || {} as PaginationData<Zone>;
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    limit: remote.limit || 0,
    total: remote.total || 0,
    last: remote.last || 0,
  }
}
// 获取专区数据
export async function getZoneWare(current: number = 1, title: string = '', limit?: number) {
  const url = getUrl(API.zone, {
    current,
    title,
    limit,
  });
  return await axios.get(url);
}


// 请求参数数据接口
interface QueryParams {
  id?: string;
  title?: string;
  category?: number;
  status?: number;
  current?: number;
}
// 商品数据接口
export interface WareData {
  category: CategoryData;
  category_id: number;
  config_limit_time: number;
  create_time: number;
  detail_image_url: string;
  goods_parameter: string;
  goods_services: string;
  id: number;
  is_recommend: number;
  is_show: number;
  parameter: [];
  price: number;
  putaway_time: number;
  putaway_type: number;
  relevance: number;
  relevance_id: number;
  sales: number;
  sort: number;
  stock: number;
  subtitle: string;
  title: string;
  update_time: number;
  video_url: string;
  viewpager_url: string;
  warehouse: [];
}
// 分类数据数据接口
interface CategoryData {
  create_time: number;
  id: number;
  name: string;
  level: number;
  parent_id: number;
  sort: number;
  status: number;
  update_time: number;
}
// 初始化商品数据
export function initWareData(remote?: PaginationData<WareData>): PaginationData<WareData> {
  remote = remote || {} as PaginationData<WareData>;
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    limit: remote.limit || 0,
    last: remote.last || 0,
    total: remote.total || 0,
  }
}
// 获取商品数据
export async function wareData(query_params?: QueryParams) {
  const url = getUrl(API.ware_data, query_params);
  return await axios.get(url);
}

// 分类数据接口
export interface Category {
  create_time: number;
  id: number;
  level: number;
  name: string;
  parent: any[];
  parent_id: number;
  sort: number;
  status: number;
  update_time: number;
}

// 初始化分类数据
export function initCategory(remote?: Category[]): Category[] {
  remote = remote || {} as Category[];
  return remote;
}
// 获取分类信息
export async function getCategoryData() {
  return await axios.get(API.category);
}

// 请求数据类型
interface SendData {
  type?: number;
  title?: string;
  content?: string;
  banner?: string;
  jump_type?: number;
  page_type?: number;
  page_url?: string;
  zone_id?: number;
  product_id?: number;
  push_now?: number;
  push_time?: number;
}
// 新增消息类型
export async function addMessageType(send_data: SendData) {
  return await axios.post(API.get_list, {
    ...send_data
  })
}


// 获取单个消息推送消息详情
export async function getMessageDetail(id: number) {
  return await axios.get(`${API.get_list}/${id}`);
}


// 编辑消息推送
export async function editMessage(send_data: SendData, id: number) {
  return await axios.put(`${API.get_list}/${id}`, {
    ...send_data
  });
}